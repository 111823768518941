import Vue from 'vue';
import { defineComponent, onMounted, ref, reactive, computed } from '@vue/composition-api';
import useGoogleMap from '@/shared/composables/useGoogleMap';
const NEARBY_SEARCH_RADIUS = 500; // in meters
export default defineComponent({
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        const google = ref(null);
        const nearbyFilters = reactive({
            transit_station: false,
            restaurant: false,
            cafe: false,
            parking: false
        });
        const nearbyList = reactive({
            restaurant: null,
            transit_station: null,
            cafe: null,
            parking: null
        });
        const placeMarkers = ref({});
        const mapRef = ref(null);
        const map = ref(null);
        const placesService = ref(null);
        const serviceAnchor = ref(null);
        const cityCircle = ref(null);
        const selectedVenueMarker = ref(null);
        const zoom = ref(16);
        const mapOptions = {
            styles: [
                {
                    featureType: 'poi.business',
                    stylers: [{ visibility: 'off' }]
                },
                {
                    featureType: 'transit',
                    elementType: 'labels.icon',
                    stylers: [{ visibility: 'off' }]
                }
            ],
            disableDoubleClickZoom: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            zoomControl: false
        };
        const labelAnchor = ref(null);
        const filteredVenues = computed(() => globalThis.$store.getters['$_venue/venues']);
        const { getNearbyPlaces, onNearbyFilterClick } = useGoogleMap(nearbyFilters, placeMarkers, nearbyList, google, map, serviceAnchor, labelAnchor);
        const getFilteredNearbyList = computed(() => {
            let result = [];
            if (nearbyFilters.restaurant) {
                result = result.concat(nearbyList.restaurant);
            }
            if (nearbyFilters.cafe) {
                result = result.concat(nearbyList.cafe);
            }
            if (nearbyFilters.transit_station) {
                result = result.concat(nearbyList.transit_station);
            }
            if (nearbyFilters.parking) {
                result = result.concat(nearbyList.parking);
            }
            return result;
        });
        const initMap = () => {
            placeMarkers.value = {};
            const types = ['restaurant', 'cafe', 'parking', 'transit_station'];
            const requests = types.map(type => ({
                location: {
                    lng: props.venue.venue_geo.longitude,
                    lat: props.venue.venue_geo.latitude
                },
                radius: NEARBY_SEARCH_RADIUS,
                type: [type]
            }));
            requests.forEach(request => placesService.value.nearbySearch(request, results => getNearbyPlaces(results, request.type[0])));
            if (cityCircle.value) {
                cityCircle.value.setMap(null);
            }
            cityCircle.value = new google.value.maps.Circle({
                strokeColor: '#009FE3',
                strokeWeight: 1,
                fillColor: '#D7F3FF',
                fillOpacity: 0.3,
                map: map.value,
                center: {
                    lng: props.venue.venue_geo.longitude,
                    lat: props.venue.venue_geo.latitude
                },
                radius: NEARBY_SEARCH_RADIUS
            });
            const MarkerWithLabel = require('markerwithlabel')(google.value.maps);
            selectedVenueMarker.value = new MarkerWithLabel({
                map: map.value,
                icon: {
                    url: '/img/common/nearby-venue-marker.svg'
                },
                position: {
                    lng: props.venue.venue_geo.longitude,
                    lat: props.venue.venue_geo.latitude
                },
                labelContent: `<span>${props.venue.venue_name}</span>`,
                labelClass: 'nearby-venue-marker', // the CSS class for the label
                labelAnchor: new google.value.maps.Point(-17, 30),
                zIndex: 10000
            });
        };
        onMounted(() => {
            // https://diegoazh.github.io/gmap-vue/#the-three-main-utilities
            Vue.$gmapApiPromiseLazy().then(mapsAPI => {
                google.value = mapsAPI;
                serviceAnchor.value = new google.value.maps.Point(7, 26);
                labelAnchor.value = new google.value.maps.Point(-17, 30);
                mapRef.value.$mapPromise.then(() => {
                    map.value = mapRef.value.$mapObject;
                    placesService.value = new google.value.maps.places.PlacesService(map.value);
                    initMap();
                });
            });
        });
        return {
            filteredVenues,
            getFilteredNearbyList,
            mapOptions,
            mapRef,
            nearbyFilters,
            zoom,
            onNearbyFilterClick
        };
    }
});
